import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Header,
  Segment,
  Form,
  Icon,
  Message,
  Image,
} from 'semantic-ui-react';
import { Route, Redirect } from 'react-router-dom';
import moment from 'moment';

import connect from 'lib/reduxConnect';

import brandLogo from 'assets/logo-placeholder.png';

import SaudiaLoginButton from '../components/saudiaLoginButton';
import IbeLoginButton from '../components/ibeLoginButton';
import ElyLoginButton from '../components/elyLoginButton';

import LoginHandler from '../containers/authHandler';

class Login extends Component {
  state = {
    login: '',
    password: '',
    formError: false,
    formErrorMessage: '',
    showOtherLoginOptions: false,
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  verifyCredentials = async () => {
    if (this.state.login === '' || this.state.password === '') {
      this.setState({
        formError: true,
        formErrorMessage: 'All fields are required',
      });
      return;
    }
    this.setState({
      formError: false,
    });
    try {
      await this.props.actions.verifyCredentials(
        this.state.login,
        this.state.password,
        this.props.noNavigation,
      );
    } catch (err) {
      this.setState({
        formError: true,
        formErrorMessage: err.message,
      });
    }
  };

  render() {
    if (!this.props.noNavigation && this.props.user !== null)
      return <Redirect to="/" />;

    // Regexes used to check if the user is coming from a customer-specific URL
    const iberiaRegex = /ibe.airintservices*/g;
    const saudiaRegex = /sau.airintservices*/g;
    const elyRegex = /ely.airintservices*/g;
    //const elyRegex = /localhost*/g;
    const isIberia = iberiaRegex.test(window.location.href);
    const isSaudia = saudiaRegex.test(window.location.href);
    const isEly = elyRegex.test(window.location.href);
    const allowOtherOptions = isIberia || isSaudia || isEly;

    return (
      <Container text className="contentMargin">
        {!this.props.noNavigation && (
          <div style={{ marginTop: '1em' }}>
            <Image src={brandLogo} style={{ margin: '0 auto' }} />
          </div>
        )}
        <Route
          path={`${this.props.match.url}/authenticated`}
          component={LoginHandler}
        />
        <Segment.Group>
          <Segment inverted color="orange">
            <Header as="h4">
              <Icon name="lock" />
              <Header.Content>
                Please sign in to your AIS account
              </Header.Content>
            </Header>
          </Segment>
          <Segment inverted color="orange" secondary>
            <Form error={this.state.formError} inverted size="large">
              <Message
                error
                header="Could not sign in"
                content={this.state.formErrorMessage}
              />
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  placeholder="User name"
                  required={!allowOtherOptions}
                  name="login"
                  onChange={this.handleChange}
                />
                <Form.Input
                  fluid
                  placeholder="Password"
                  type="password"
                  name="password"
                  required={!allowOtherOptions}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Form.Button primary onClick={this.verifyCredentials}>
                Connect
              </Form.Button>
              {allowOtherOptions && (
                <Form.Button
                  onClick={() =>
                    this.setState(prevState => ({
                      showOtherLoginOptions: !prevState.showOtherLoginOptions,
                    }))
                  }
                >
                  {this.state.showOtherLoginOptions ? 'Hide' : 'Show'} other
                  login options
                </Form.Button>
              )}
            </Form>
          </Segment>
          {this.state.showOtherLoginOptions && (
            <Segment inverted color="orange" secondary>
              <Form.Group widths="equal">
                {isSaudia && <SaudiaLoginButton />}
                {isIberia && <IbeLoginButton />}
                {isEly && <ElyLoginButton />}
              </Form.Group>
            </Segment>
          )}
        </Segment.Group>
        {!this.props.noNavigation && (
          <div
            style={{
              marginTop: '1em',
              marginBottom: '1em',
              textAlign: 'right',
              fontWeight: 'lighter',
              color: 'gray',
            }}
          >
            &copy; {moment().format('Y')} AirInt' Services - All rights reserved
          </div>
        )}
      </Container>
    );
  }
}

Login.propTypes = {
  noNavigation: PropTypes.bool,
};

export default connect()(Login);
