import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Image } from 'semantic-ui-react';

import { URI } from 'config/network';

import elyLogo from 'assets/logo-ely.png';

export default class ElyLoginButton extends Component {
  loginWithEly = () => {
    window.location.href = `${URI.auth.ely}?udid=webreporting`;
  };

  render() {
    return (
      <Form.Button
        onClick={this.loginWithEly}
        primary
        style={{
          background: 'white',
        }}
      >
        <div>
          <Image src={elyLogo} />
        </div>
      </Form.Button>
    );
  }
}

ElyLoginButton.propTypes = {
  open: PropTypes.bool,
};
